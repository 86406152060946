import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'remote_background_image_url',
      value: [],
      name: 'image',
      label: i18n.t('form.members.memberCardImage'),
      Object: {
        limit: 1
      }
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.members.tierName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.members.tierName') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.members.tierNameEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'required_points',
      name: 'input',
      type: 'number',
      label: i18n.t('form.members.minimumPoints'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.members.minimumPoints') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'member_privileges_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.members.benefits'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 24,
        prop: 'remote_icon_url',
        value: [],
        name: 'image',
        label: i18n.t('form.members.icon'),
        Object: {
          limit: 1
        }
      }, {
        span: 12,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.members.name'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 12,
        prop: 'name_en',
        name: 'input',
        label: i18n.t('form.members.nameEN'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 24,
        prop: 'short_description',
        name: 'input',
        label: i18n.t('form.common.shortDescriptionCn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 24,
        prop: 'short_description_en',
        name: 'input',
        label: i18n.t('form.common.shortDescriptionEn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 24,
        prop: 'description',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.members.detail'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
          rows: 4
        }
      }, {
        span: 24,
        prop: 'description_en',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.members.detailEN'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
          rows: 4
        }
      }]
    }]
  }]
}
